export const MESSAGE_GROUP_FULL_FRAGMENT = `
  fragment messageGroupFullFragment on MessageGroup {
    uid
    schemaCode
    groupType: type
    active
    isSessionModerator: _isSessionModerator(myUid: "%authUser%")
    moderators: _moderators {
      uid
    }
    isMuted: _isMuted(myUid: "%authUser%")
    target {
        uid
        __typename
        ... on Session {
            name
            imageFileResource {
                path
                schemaCode
            }
            startTime
        }
        ... on Exhibitor {
            name
            logoFileResource {
                path
                schemaCode
            }
        }
        ... on Meeting {
            subject
            endTimestamp
            startTimestamp
            createdTime
            creatorUser {
                uid
                firstName
                lastName
                name
                pictureFileResource {
                    schemaCode
                    path
                }
            }
            participants {
                uid
                state
                user {
                    uid
                    firstName
                    lastName
                    name
                    pictureFileResource {
                        schemaCode
                        path
                    }
                }
            }
        }
    }
    guests {
        uid
        schemaCode
        token
        group {
            uid
        }
    }
    users {
        uid
        firstName
        lastName
        jobTitle
        banned
        pictureFileResource {
            uid
            schemaCode
            path
        }
        _ourConnection(myUid: "%authUser%") {
            uid
            linkState
            connectedUser {
                uid
                name
            }
        }
        _roleInCompany(companyUid: "%companyId%", filter: {state: "ACCEPTED"}) {
          uid
        }
    }
    inactiveUsers {
        uid
        firstName
        lastName
        jobTitle
        banned
        pictureFileResource {
            uid
            schemaCode
            path
        }
        _ourConnection(myUid: "%authUser%") {
            uid
            linkState
            connectedUser {
                uid
                name
            }
        }
    }
    messages(orderBy: senttimestamp_desc, first: 1, filter: {type_in: ["REGULAR", "LINK"]}) {
        uid
        type
        content
        senttime
        senttimestamp
        user {
            uid
            firstName
            lastName
            jobTitle
            pictureFileResource {
                uid
                schemaCode
                path
            }
        }
    }
    myState: _myState(myUid: "%authUser%") {
        uid
        lastReadTimestamp
        hideMessageBeforeTimestamp
        muted
        user {
            uid
            firstName
            lastName
            jobTitle
            banned
            pictureFileResource {
                uid
                schemaCode
                path
            }
        }
    }
    creatorUser {
          uid
          firstName
          lastName
          pictureFileResource {
              uid
              schemaCode
              path
          }
        }
  }
`;
